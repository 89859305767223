export const email = [
  v => !!v || 'E-mail is required',
  v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
]

export const password = [
  v => !!v || 'Password is required',
  v => (v && v.length >= 6) || 'Name must be 6 or more characters',
]

export const confirmPassword = password => {
  return [v => v === password || 'Passwords must match']
}

export const numImages = existingImages => [
  v => v.length + existingImages.length <= 6 || 'Up to 6 images allowed total',
]
